import Bg from "../../../../content/visualisation/2021-06-02-Green-Industrial-Revolution/nuclear-power-station.jpg";
import { Title, InfoDiv, SourceTitle, BrandTitle, SubTitle } from "../../../../content/visualisation/2021-06-02-Green-Industrial-Revolution/style";
import * as React from 'react';
export default {
  Bg,
  Title,
  InfoDiv,
  SourceTitle,
  BrandTitle,
  SubTitle,
  React
};