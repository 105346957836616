import FlowChart from "../../../../content/visualisation/2021-08-04-Which-Statistical-Test-To-Use-For-Two-Variables/flowChart/flowChart";
import { ReactFlowProvider } from "react-flow-renderer";
import Thumbnail from "../../../../content/visualisation/2021-08-04-Which-Statistical-Test-To-Use-For-Two-Variables/thumbnail/thumb";
import Thumb from "../../../../content/visualisation/2021-08-04-Which-Statistical-Test-To-Use-For-Two-Variables/thumbnail/flowChartInside.png";
import * as React from 'react';
export default {
  FlowChart,
  ReactFlowProvider,
  Thumbnail,
  Thumb,
  React
};