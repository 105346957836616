import React from "react";
import Scrollspy from "react-scrollspy";
import Fade from "react-reveal/Fade";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
export default {
  React,
  Scrollspy,
  Fade,
  TiChevronLeft,
  TiChevronRight
};